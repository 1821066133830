<template>
  <div style="height: 100%">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="余额明细"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-select v-model="searchForm.state" placeholder="请选择" size="small" style="margin-right: 10px" @change="search">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
              </el-form-item>
              <!-- <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="SearchInputKeydown"></el-input>
                            </el-form-item> -->
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields" worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="small" class="el-icon-download">导出当前数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="small" class="el-icon-download">导出全部数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <div class="title-div">
                  余额<span class="num">￥<countTo :startVal="0" :endVal="balance" :decimals="2" :duration="1000"> </countTo></span>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="title-div">
                  未入账金额<span class="num">￥<countTo :startVal="0" :endVal="waitMoney" :decimals="2" :duration="1000"> </countTo></span>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <el-table :data="tableData" stripe height="calc(100% - 70px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="orderNum" label="订单编号" width="250px">
            <template slot-scope="scope">{{ scope.row.mzOrderNum?scope.row.mzOrderNum:scope.row.orderNum }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="时间" align="center" width="150px">
            <template slot-scope="scope"> {{ scope.row.createTime | timefilters }} </template>
          </el-table-column>
          <el-table-column prop="purpose" label="类型" align="center">
            <template slot-scope="scope">
              <!-- 支出 -->
              <el-tag size="small" v-if="scope.row.purpose == 'withdrawal'">提现</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'refund'">退款</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'buyMember'">购买会员</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'upgradeMember'">升级会员</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'renewalUpgrade'">续费升级订单</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'renewalMember'">续费会员</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'payVideo'">购买视频</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'teamPerformanceIncome'">团队绩效发放</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'MZRefund'">门诊退款</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'BalanceTransfers'">转账</el-tag>
              <!-- 收入 -->
              <el-tag size="small" v-if="scope.row.purpose == 'teamPerformanceDistribution'">团队绩效发放</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'recharge'">充值</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'entryGoods'">商品入账</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'distribution'">分销获利</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'shareMoney'">分享获利</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'shareMoneySecond'">分享获利抽佣</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'publicShare'">公益分红</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'purchaseGoods'">购买商品</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'refundChargebackMoney'">退款手续费</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'saleVideo'">出售视频</el-tag>
              <el-tag size="small" v-if="scope.row.purpose == 'MZPayment'">门诊付款</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="账单类型" align="center">
            <template slot-scope="scope">
              <el-tag type="warning" size="small" v-if="scope.row.type == '1'">支出</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.type == '2'">收入</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="金额（元）" align="center"> </el-table-column>
          <!-- <el-table-column prop="afterBalance" label="当前余额" align="center">
                    </el-table-column> -->
          <el-table-column prop="paymentMethod" label="支付方式" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.paymentMethod == '1'">微信</span>
              <span v-if="scope.row.paymentMethod == '2'">支付宝</span>
              <span v-if="scope.row.paymentMethod == '0'">余额</span>
              <span v-if="scope.row.paymentMethod == '3'">银行卡</span>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag size="small" type="success" v-if="scope.row.state == 1">已到账</el-tag>
              <el-tag size="small" type="warning" v-if="scope.row.state == 0">待入账</el-tag>
              <el-tag size="small" type="danger" v-if="scope.row.state == -1">已拒绝</el-tag>
              <el-tag size="small" type="info" v-if="scope.row.state == -2">已取消</el-tag>
              <el-tag size="small" type="danger" v-if="scope.row.state == -3">已退费</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
import { Index } from '@/components/HospitalDomain/Index.js'
import { Balance } from '@/components/HospitalDomain/Balance.js'
var loading
export default {
  name: 'BalanceDetail',
  components: {
    countTo,
  },
  data() {
    var index = new Index(this.TokenClient, this.Services.Authorization)
    var balance = new Balance(this.TokenClient, this.Services.Authorization)

    return {
      IndexDomain: index,
      BalanceDomain: balance,
      assetsId: this.$route.query.assetsId, //列表页id
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      tableData: [],
      searchForm: {
        state: '0',
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: this.$route.query.datepicker,
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      statusOption: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '2',
          label: '收入',
        },
        {
          value: '1',
          label: '支出',
        },
      ],
      title: '余额明细',
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: 'orderNum',
          callback: (val) => {
            return 'NO：' + val.orderNum
          },
        },
        时间: {
          filed: 'createTime',
          callback: (val) => {
            var val = val.createTime
            return this.$fliters.timefilters(val)
          },
        },
        类型: {
          filed: 'purpose',
          callback: (val) => {
            var val = val.purpose
            var str = ''
            switch (val) {
              case 'withdrawal':
                str = '提现'
                break
              case 'refund':
                str = '退款'
                break
              case 'buyMember':
                str = '购买会员'
                break
              case 'upgradeMember':
                str = '升级会员'
                break
              case 'renewalUpgrade':
                str = '续费升级订单'
                break
              case 'renewalMember':
                str = '续费会员'
                break
              case 'payVideo':
                str = '购买视频'
                break
              case 'recharge':
                str = '充值'
                break
              case 'entryGoods':
                str = '商品入账'
                break
              case 'distribution':
                str = '分销获利'
                break
              case 'shareMoneySecond':
                str = '分享获利抽佣'
                break
              case 'publicShare':
                str = '公益分红'
                break
              case 'purchaseGoods':
                str = '购买商品'
                break
              case 'refundChargebackMoney':
                str = '退款手续费'
                break
              case 'saleVideo':
                str = '出售视频'
                break
              case 'MZRefund':
                str = '门诊退款'
                break
              case 'MZPayment':
                str = '门诊付款'
                break
              case 'BalanceTransfers':
                str = '转账'
                break
            }
            return str
          },
        },
        账单类型: {
          filed: 'type',
          callback: (val) => {
            return val == 1 ? '支出' : '收入'
          },
        },
        变动金额: 'amount',
        状态: {
          filed: 'state',
          callback: (val) => {
            var str = ''
            var val = val.state
            switch (val) {
              case 1:
                str = '已到账'
                break
              case 0:
                str = '待入账'
                break
              case -1:
                str = '已拒绝'
                break
              case -2:
                str = '已取消'
                break
              case -3:
                str = '已退费'
                break
            }
            return str
          },
        },
        支付方式: {
          filed: 'paymentMethod',
          callback: (val) => {
            var val = val.paymentMethod
            var str = ''
            switch (val) {
              case 1:
                str = '微信'
                break
              case 2:
                str = '支付宝'
                break
              case 0:
                str = '余额'
                break
              case 3:
                str = '银行卡'
                break
            }
            return str
          },
        },
        当前余额: 'afterBalance',
      },
      balance: 0,
      waitMoney: 0,
    }
  },
  mounted() {
    this.getList()
    this.getIndexData()
  },
  methods: {
    //获取顶部数据
    getIndexData() {
      var _this = this
      _this.IndexDomain.IndexData(
        this.userInfo.organizationId,
        function (data) {
          _this.balance = data.data.balance
          _this.waitMoney = data.data.waitMoney
        },
        function (error) {}
      )
    },
    goBack() {
      this.$router.go(-1)
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    search() {
      this.PageIndex = 1
      this.getList()
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this
      return new Promise((resolve, reject) => {
        var item = _this.searchForm
        if (_this.searchForm.datepicker) {
          item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
          item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
        } else {
          item.startTime = ''
          item.endTime = ''
        }
        _this.BalanceDomain.BalanceDetail(
          item.startTime,
          item.endTime,
          item.keyWord,
          item.state,
          1,
          _this.DataCount,
          function (data) {
            resolve(data.data.results)
          },
          function (err) {
            resolve('')
            console.log(err)
          }
        )
      })
    },
    getList() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.BalanceDomain.BalanceDetail(
        item.startTime,
        item.endTime,
        item.keyWord,
        item.state,
        _this.PageIndex,
        null,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
          if (!item.keyWord) {
            _this.DataCount = data.data.dataTotal
          }
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped>
.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

.messages {
  margin-top: 13px;
  margin-right: 20px;
}

.messages .el-icon-bell {
  font-size: 26px;
}

/*content*/

.table-top {
  margin-bottom: 15px;
}

.iq-search-bar {
  display: inline-block;
  margin-right: 20px;
}

.iq-search-bar .searchbox {
  width: 480px;
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #ceebee;
  line-height: 1.8;
}

.dialog-form {
  width: 430px;
}

.iq-search-bar .el-form-item {
  margin-bottom: 0;
}

.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
.title-div {
  font-size: 16px;
  font-weight: 700;
  color: #303133;
  /* margin-top: 10px; */
}

.title-div .num {
  margin-left: 10px;
  font-size: 18px;
  color: red;
}
</style>
